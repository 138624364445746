import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import {
    Box,
    Button,
    Chip,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link, useLoaderData } from 'react-router-dom';

import {
    PROJECT_FEATURE_FLAGS,
    SYNC_FREQUENCIES,
    SYNC_FREQUENCY_LABELS,
    SYNC_RUN_STATUSES,
    SYNC_RUN_STATUS_LABELS,
    SYNC_STATUSES,
    SYNC_STATUS_LABELS,
} from '@cta-pond/constants';

import EmptyStatePlaceholder from 'components/EmptyStatePlaceholder';
import PageHeader from 'components/PageHeader';
import SyncPointLogo from 'components/SyncPointLogo';
import { convertFirestoreTimestampToDate } from 'helpers/firestore';
import useFeatureFlags from 'hooks/useFeatureFlags';

function SyncStatus({ status }) {
    SyncStatus.propTypes = {
        status: PropTypes.oneOf(Object.values(SYNC_STATUSES)).isRequired,
    };

    const statusBadgeColors = {
        [SYNC_STATUSES.ACTIVE]: 'success',
        [SYNC_STATUSES.DRAFT]: 'default',
        [SYNC_STATUSES.ERROR]: 'error',
        [SYNC_STATUSES.INACTIVE]: 'default',
        [SYNC_STATUSES.QUEUED]: 'warning',
        [SYNC_STATUSES.PROCESSING]: 'info',
    };

    return (
        <Chip
            variant="outlined"
            label={SYNC_STATUS_LABELS[status] ?? 'Unknown'}
            color={statusBadgeColors[status] ?? 'default'}
            sx={{ fontWeight: 600 }}
        />
    );
}

function SyncRunStatus({ status }) {
    SyncRunStatus.propTypes = {
        status: PropTypes.oneOf(Object.values(SYNC_RUN_STATUSES)).isRequired,
    };

    const statusBadgeColors = {
        [SYNC_RUN_STATUSES.RUNNING]: 'secondary',
        [SYNC_RUN_STATUSES.SUCCEEDED]: 'success',
        [SYNC_RUN_STATUSES.FAILED]: 'error',
    };

    return (
        <Chip
            variant="outlined"
            label={SYNC_RUN_STATUS_LABELS[status] ?? 'Unknown'}
            color={statusBadgeColors[status] ?? 'default'}
            size="small"
            sx={{ fontWeight: 600 }}
        />
    );
}

function SyncRunResultsLink({ resultsUrl, linkText = 'View results' }) {
    SyncRunResultsLink.propTypes = {
        resultsUrl: PropTypes.string.isRequired,
        linkText: PropTypes.string,
    };

    return (
        <Button variant="text" href={resultsUrl} target="_blank" sx={{ fontWeight: 600 }}>
            {linkText} <LaunchIcon sx={{ ml: 1, fontSize: '16px' }} />
        </Button>
    );
}

function ExpandableSyncTableRow({ sync }) {
    ExpandableSyncTableRow.propTypes = {
        sync: PropTypes.shape({
            name: PropTypes.string.isRequired,
            status: PropTypes.oneOf(Object.values(SYNC_STATUSES)).isRequired,
            source: PropTypes.object.isRequired,
            destination: PropTypes.object.isRequired,
            frequency: PropTypes.oneOf(Object.values(SYNC_FREQUENCIES)).isRequired,
            syncRuns: PropTypes.arrayOf(
                PropTypes.shape({
                    status: PropTypes.string.isRequired,
                    createdAt: PropTypes.object.isRequired,
                    resultsUrl: PropTypes.string,
                }),
            ),
        }).isRequired,
    };

    const [isExpanded, setIsExpanded] = useState(false);

    const { name, source, destination, frequency, status, syncRuns = [] } = sync;
    const [{ createdAt: lastRunAt } = {}] = syncRuns;
    const runCount = syncRuns.length;
    const hasSyncRun = runCount > 0;

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label={`View sync runs for ${name}`}
                        size="small"
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ maxWidth: '400px' }}>{name}</TableCell>
                <TableCell>
                    <SyncStatus status={status} />
                </TableCell>
                <TableCell>
                    <SyncPointLogo pointType={source.type} sx={{ pl: 0 }}>
                        {source.name}
                    </SyncPointLogo>
                </TableCell>
                <TableCell>
                    <SyncPointLogo pointType={destination.type} sx={{ pl: 0 }}>
                        {destination.name}
                    </SyncPointLogo>
                </TableCell>
                <TableCell>{SYNC_FREQUENCY_LABELS[frequency]}</TableCell>
                <TableCell>{runCount}</TableCell>
                <TableCell>{hasSyncRun ? convertFirestoreTimestampToDate(lastRunAt) : '-'}</TableCell>
            </TableRow>
            {isExpanded && (
                <TableRow>
                    <TableCell
                        sx={{
                            px: 12,
                            py: 2,
                            backgroundColor: 'grey.100',
                        }}
                        colSpan={9}
                    >
                        <Box sx={{ backgroundColor: 'white' }}>
                            {hasSyncRun ? (
                                <Table size="small" aria-label={`Sync runs for ${name}`}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date / time of run</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Result</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {syncRuns.map(({ createdAt, status, resultsUrl }, idx) => (
                                            <TableRow key={`${name}-${idx}`}>
                                                <TableCell>{convertFirestoreTimestampToDate(createdAt)}</TableCell>
                                                <TableCell>
                                                    <SyncRunStatus status={status} />
                                                </TableCell>
                                                {resultsUrl && (
                                                    <TableCell>
                                                        <SyncRunResultsLink resultsUrl={resultsUrl} />
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                <EmptyStatePlaceholder title="This sync has not been run yet" />
                            )}
                        </Box>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

function NewSyncButton() {
    const buttonLabel = 'Create new sync';

    return (
        <Button component={Link} to="new" variant="contained" color="secondary" aria-label={buttonLabel}>
            {buttonLabel}
        </Button>
    );
}

export default function SyncsIndexPage() {
    const featureFlags = useFeatureFlags();
    const { syncs } = useLoaderData();

    const doesProjectHaveSyncs = Boolean(syncs?.length);

    return (
        <>
            <PageHeader
                title="Syncs"
                subtitle="View and manage all your syncs in one place"
                showDemoLabel={featureFlags[PROJECT_FEATURE_FLAGS.IS_HOPPER_DEMO_ENABLED]}
                action={doesProjectHaveSyncs ? <NewSyncButton /> : null}
            />
            <section>
                {doesProjectHaveSyncs ? (
                    <TableContainer
                        component={Paper}
                        sx={{ overflowX: 'auto', border: 1, borderColor: 'grey.300', borderBottom: 0 }}
                        square
                    >
                        <Table sx={{ minWidth: 650 }} aria-label="Syncs table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Sync name</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Source</TableCell>
                                    <TableCell>Destination</TableCell>
                                    <TableCell>Frequency</TableCell>
                                    <TableCell>Run count</TableCell>
                                    <TableCell>Last run</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {syncs.map((sync) => (
                                    <ExpandableSyncTableRow key={sync.id} sync={sync} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <EmptyStatePlaceholder
                        title={'No syncs to show'}
                        subtitle={'Once you create your first sync it will show up here.'}
                    >
                        <NewSyncButton />
                    </EmptyStatePlaceholder>
                )}
            </section>
        </>
    );
}
