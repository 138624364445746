import { QUERY_PARAMS } from '@cta-pond/constants';
import {
    Autocomplete,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import PageHeader from 'components/PageHeader';
import { convertFirestoreTimestampToDate } from 'helpers/firestore';
import { useLoaderData, useNavigation, useSearchParams } from 'react-router-dom';

export default function SyncSchedulePage() {
    const { syncs, allPartnerIds } = useLoaderData();
    const [searchParams, setSearchParams] = useSearchParams();
    const { state } = useNavigation();

    const onChangePartnerFilter = (event, value) => {
        const newValues = value ? { [QUERY_PARAMS.PARTNER_ID]: value } : {};
        setSearchParams(newValues);
    };

    return (
        <>
            <PageHeader title="Sync Schedule" subtitle="View sync details across partners and platforms" />
            <section>
                <LinearProgress sx={{ opacity: state === 'loading' ? 1 : 0 }} />
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: '85vh',
                        border: 1,
                        borderColor: 'grey.300',
                        borderBottom: 0,
                    }}
                    square
                >
                    <Table sx={{ minWidth: 650 }} aria-label="Syncs table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ maxWidth: 400, minWidth: 400 }}>Sync ID</TableCell>
                                <TableCell sx={{ minWidth: 300 }}>Partner</TableCell>
                                <TableCell sx={{ minWidth: 200 }}>Source</TableCell>
                                <TableCell>Schedule</TableCell>
                                <TableCell sx={{ minWidth: 200 }}>Last run</TableCell>
                                <TableCell sx={{ minWidth: 200 }}>Last run status</TableCell>
                                <TableCell sx={{ minWidth: 200 }}>Next run</TableCell>
                                <TableCell sx={{ minWidth: 400 }}>Tags</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>
                                    <Autocomplete
                                        id="partner-filter"
                                        value={searchParams.get(QUERY_PARAMS.PARTNER_ID)}
                                        onChange={onChangePartnerFilter}
                                        options={allPartnerIds}
                                        fullWidth={true}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" label="Filter by partner" />
                                        )}
                                    />
                                </TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {syncs.map((sync) => (
                                <TableRow key={sync.id}>
                                    <TableCell>{sync.id}</TableCell>
                                    <TableCell>{sync.partnerName}</TableCell>
                                    <TableCell>{sync.vendorName}</TableCell>
                                    <TableCell>{sync.scheduleInterval}</TableCell>
                                    <TableCell>{convertFirestoreTimestampToDate(sync.lastExecutionDate)}</TableCell>
                                    <TableCell>{sync.lastState}</TableCell>
                                    <TableCell>{convertFirestoreTimestampToDate(sync.nextDAGRun)}</TableCell>
                                    <TableCell>{sync.tags?.map((tag) => tag.name)?.join(', ') ?? '-'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </section>
        </>
    );
}
